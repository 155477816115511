<template>
  <div class="material" v-if="item">
    <div class="material__image-container">
      <!-- <div class="material__tag_new" v-if="item.is_new">Новинка</div> -->
      <img
        class="material__image"
        v-if="item.preview"
        :src="this.$root.host + item.preview"
        @click="goTo()"
      />
      <div class="material__category">
        <span v-if="item.type && Object.keys(item.type).length">
          {{ item.type.name }}
        </span>
        <div class="material__lock"  v-if="!item.is_available">
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.75 6.44444V4.88889C10.75 2.74112 9.07107 1 7 1C4.92893 1 3.25 2.74111 3.25 4.88889V6.44444M7 9.94444V11.5M4.6 15H9.4C10.6601 15 11.2902 15 11.7715 14.7457C12.1948 14.522 12.539 14.165 12.7548 13.726C13 13.2269 13 12.5735 13 11.2667V10.1778C13 8.87099 13 8.21759 12.7548 7.71847C12.539 7.27942 12.1948 6.92247 11.7715 6.69876C11.2902 6.44444 10.6601 6.44444 9.4 6.44444H4.6C3.33988 6.44444 2.70982 6.44444 2.22852 6.69876C1.80516 6.92247 1.46095 7.27942 1.24524 7.71847C1 8.21759 1 8.87099 1 10.1778V11.2667C1 12.5735 1 13.2269 1.24524 13.726C1.46095 14.165 1.80516 14.522 2.22852 14.7457C2.70982 15 3.33988 15 4.6 15Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="material__text-container" @click="goTo()">
      <div class="material__tags">
        <div
          class="material__tag"
          v-for="(nosology, i) in item.nosology"
          :key="`nos${i}`"
          v-html="nosology.title"
        ></div>
        <div
          class="material__tag"
          v-for="(disease, i) in item.disease"
          :key="`dis${i}`"
          v-html="disease.title"
        ></div>
      </div>
      <div class="material__title" v-html="item.title"></div>
      <!-- <div
        class="material__description"
        v-if="item.preview"
        v-html="item.preview"
      ></div> -->
    </div>
    <PopUpDefault
      class="training-popup"
      :class="[inputClass]"
      @close="openPopup(false)"
      v-if="trainingPopupState"
    >
      <div class="training-popup__title">
        Тренинг доступен только для пользователей имеющих специальный код
        доступа
      </div>
      <div class="training-popup__description">
        Для получения доступа введите код в окно ниже
      </div>
      <div class="training-popup__label">Код для доступа к тренингу</div>
      <div class="training-popup__input-container" >
        <div class="training-popup__input-wrap">
          <input type="text" v-model="code" class="training-popup__input" />
          <div class="training-popup__success-icon">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4L7 11L3.5 7"
                stroke="#C4D600"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>

        <div class="training-popup__error-text">Неправильный код</div>
      </div>
      <div
        class="
          modal-popup__btn-accept
          d-md-inline-flex
          button button_pink
          ml-auto
          mr-auto
          mt-4
          px-8
        "
        @click="sendCode()"
      >
        Отправить
      </div>
    </PopUpDefault>
  </div>
</template>

<script>
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import { bus } from "@/main";
export default {
  data: () => ({
    count: 0,
    code: null,
    inputClass: '', 
    trainingPopupState: false,
  }),
  components: {
    PopUpDefault,
  },
  name: "Material",
  props: {
    item: Object,
  },
  computed: {
    link() {
      return { name: "EducationDetail", params: { slug: this.item.slug } };
    },
  },
  methods: {
    async sendCode() {
      this.$axios({
        method: "POST",
        url: `/api/education/trenings/${this.item.slug}/code/`,
        data: {code: this.code}
      }).then((response) => {
        bus.$emit("scrollLock", false);
        this.$router.push(this.link).catch(() => {});
        this.inputClass = 'training-popup_success'
      }).catch((error) => {
        console.log(error)
        this.inputClass = 'training-popup_error'
      })
    },
    async goTo() {
      if (this.item.is_available) {
        this.$router.push(this.link).catch(() => {});
      } else {
        this.openPopup(true)
      }
    },
    openPopup(state) {
      this.trainingPopupState = state;
      bus.$emit("scrollLock", state);
    },
  },
  watch: {
    code() {
      this.inputClass = ''
    }
  }
};
</script>

<style lang="scss" scoped>
.material {
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d2d2d2;

  &:hover {
    .material__title {
      color: #830051;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    height: 214px;
    @media screen and (max-width: 1220px) {
      height: 150px;
    }

    @media screen and (max-width: 767px) {
      height: 180px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &__category {
    padding: 8px 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #ffffff;
    // background: rgba(60, 66, 66, 0.6);
    color: #fff;
    background-color: #b2b4b4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text-container {
    padding: 16px 24px 24px;
    @media screen and (max-width: 1220px) {
      padding: 16px;
    }
  }

  &__lock {
    margin-left: auto;
  }

  &__tags {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
    transition: 0.3s;

    @media screen and (max-width: 1220px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__description {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #656969;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: none;
  }
}

.training-popup {
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: #830051;
    margin-bottom: 24px;
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #3c4242;
    margin-bottom: 24px;
  }

  &__label {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #1f1f1f;
  }

  &__input-container {
    position: relative;
    max-width: 280px;
    margin: 0 auto;
  }

  &__input {
    padding: 0 32px 0 16px;
    width: 100%;
    height: 100%;
    max-width: 384px;
    background-color: #f8f8f8;
    border: 1px solid #d2d2d2;
    border-radius: 99px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b2b4b4;
      opacity: 1; /* Firefox */
    }

    &:focus {
      border-color: #d0006f;
    }

    &-wrap {
      position: relative;
      width: 100%;
      height: 40px;
    }

    
  }

  &__error-text {
      display: none;
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #cb4050;
    }

  &__success-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &_error {
    & .training-popup__input {
      border-color: #cb4050;
      background-color: #faebed;
      color: #cb4050;
    }

    & .training-popup__error-text {
      display: block;
      color: #cb4050 !important;
    }
  }

  &_success {
    & .training-popup__input {
      border-color: #c4d600;
      background-color: #fafce9;
    }

    & .training-popup__success-icon {
      display: block;
    }
  }
}
</style>